.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-container th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.table-container thead {
  background-color: #f2f2f2;
}

.table-container th {
  background-color: #ddd;
  font-weight: bold;
}
