@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url("https://fonts.googleapis.com/css2?family=Caprasimo&family=Roboto+Slab:wght@400;700&display=swap");

* {
  font-family: "Roboto Slab", sans-serif;
}
:root {
  /* background-color: #1e293b; */
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dark input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dark input[type="month"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* .highlighted-text {
  text-align: center;
  animation: attractAnimation 2s infinite;
}

@keyframes attractAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} */

.highlighted-text {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #fff 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: oscillateEffect 4s linear infinite;
}

@keyframes oscillateEffect {
  0% {
    background-position: 0% center;
    transform: scale(1);
  }
  50% {
    background-position: 100% center;
    transform: scale(1.1);
  }
  100% {
    background-position: 0% center;
    transform: scale(1);
  }
}

.wrapper.two {
  font-size: 6rem;
  font-family: "Play", sans-serif;
}
.neon h3 {
  margin: 1rem auto;
  animation: neon 1s ease infinite;
}
@keyframes neon {
  0%,
  100% {
    text-shadow: 0 0 10px #ef00e3a8, 0 0 20px #ef00e3a8, 0 0 20px #ef00e3a8,
      0 0 20px #ef00e3a8, 0 0 2px #fed128, 2px 2px 2px #806914;
    color: #fff;
  }
  50% {
    text-shadow: 0 0 2px #800e0b, 0 0 5px #800e0b, 0 0 5px #800e0b,
      0 0 5px #800e0b, 0 0 2px #800e0b, 4px 4px 2px #40340a;
    color: #1e293b;
  }
}

/* Hide the default arrow */
.custom-select {
  -webkit-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16"><path d="M.94 5.28a.75.75 0 0 1 1.121-.02l6.882 6.219 6.883-6.219a.75.75 0 0 1 1.102.998l-.052.061-7.5 6.75a.75.75 0 0 1-1.05.02l-7.5-6.75a.75.75 0 0 1 .051-1.058z"/></svg>');
}

.dark .custom-select {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-caret-down" viewBox="0 0 16 16"><path d="M.94 5.28a.75.75 0 0 1 1.121-.02l6.882 6.219 6.883-6.219a.75.75 0 0 1 1.102.998l-.052.061-7.5 6.75a.75.75 0 0 1-1.05.02l-7.5-6.75a.75.75 0 0 1 .051-1.058z"/></svg>');
}

/* Style the select element */
.custom-select:focus {
  /* outline: none;
  box-shadow: none; */
}

.rmsc {
  --rmsc-main: #4285f4 !important;
  --rmsc-hover: #f1f3f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 10px !important;
  --rmsc-radius: 8px !important;
  --rmsc-h: 38px !important;
  font-size: 14px;
  line-height: 20px;
}

.dark .rmsc {
  --rmsc-main: #4285f4 !important;
  --rmsc-hover: #111827 !important;
  --rmsc-selected: #374151 !important;
  --rmsc-border: #4b5563 !important;
  --rmsc-gray: #aaa !important;
  --rmsc-bg: #374151 !important;
  --rmsc-p: 10px !important;
  --rmsc-radius: 8px !important;
  --rmsc-h: 38px !important;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}
